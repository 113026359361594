import client from "@/services/client";
import config from "../../config";

export default {
	async getTopStakers(payload) {
		return await client.get("/ltsp/top-stakers", payload, {
			baseURL: config.url.stakingNode,
		});
	},
	async getStatistics(payload) {
		return await client.get("/ltsp/stats", payload, {
			baseURL: config.url.stakingNode,
		});
	},
	async getStaker(address, payload) {
		return await client.get(`/ltsp/staker/${address}`, payload, {
			baseURL: config.url.stakingNode,
		});
	},
};
